@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  font-family: "Roboto", sans-serif;
  color: white;
}
.bg-menu {
  background-color: #202020;
}
.text-primary {
  color: #aaa;
}
.bg-secondary {
  background-color: #383838;
}
.active {
  color: white;
  font-weight: 500;
}

.cuttextarea {
  height: 70px;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
body {
  background-color: black;
}
.h-44 {
  height: 11rem;
}
@media (min-width: 768px) {
  .md\:h-44 {
    height: 11rem;
  }
}

.fixedTopLeft {
  position: fixed;
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
}

.fixedTopRight {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
}

.fixedBottomLeft {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 50%;
}

.fixedBottomRight {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
}

.invisibleObject {
  opacity: 0;
}

.invisibleObject:hover {
  opacity: 0.2;
}

.invisibleObject,
.invisibleObject8 {
  opacity: 0;
}

.invisibleObject8 {
  opacity: 0.5;
  background-color: black;
}
.is-playing .invisibleObject8 {
  opacity: 0;
}

.is-playing .invisibleObject8:hover {
  opacity: 0.2;
  background-color: black;
}

.fixedBottomRightYoutube {
  position: fixed;
  top: 10px;
  bottom: 50%;
  right: 5px;
  width: 50%;
  overflow: hidden;
}

.fixedBottomLeftYoutube {
  position: fixed;
  top: 10px;
  bottom: 50%;
  left: 0;
  width: 50%;
  overflow: hidden;
}

.fixedBottomRightYoutubeCorner {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 55px;
  height: 35px;
}

.xltext {
  font-size: 55px;
  line-height: 60px;
  margin-bottom: 10px;
}

.fixedBottomLeftYoutubeCorner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 55px;
  height: 35px;
}
.extension {
  min-width: 600px;
  min-height: 800px;
}
.extension .hide-in-extension {
  display: none;
}
.search-box {
  right: 1px;
  top: -36px;
}
.grayscale {
  filter: grayscale(100%);
}
::-webkit-scrollbar {
  width: 14px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: #20202070; /* color of the tracking area */
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #a7a5a590; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #242323; /* creates padding around scroll thumb */
}
body {
  overflow-y: scroll;
}
.max-h-40 {
  max-height: 13rem;
}
.text-shadow {
  text-shadow: 2px 2px 4px black;
}
.w-screen-minus {
  height: 80vh;
}
.addComma::after {
  content: ",";
}
